import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`When authoring markdown using the Carbon Gatsby theme, code blocks have some
extra super powers you can take advantage of. We provide carbon-themed syntax
highlighting as well as optional `}<inlineCode parentName="p">{`path`}</inlineCode>{` and `}<inlineCode parentName="p">{`src`}</inlineCode>{` features.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Horizontal overflow</Title>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "path=/directory/file.mdx src=https://gatsby-theme-carbon.now.sh",
        "path": "/directory/file.mdx",
        "src": "https://gatsby-theme-carbon.now.sh"
      }}>{`## Path and src w/ overflow

This example overflows to demonstrate the text fading out under the side bar.
This example overflows to demonstrate the text fading out under the side bar.
This example overflows to demonstrate the text fading out under the side bar.
This example overflows to demonstrate the text fading out under the side bar.
`}</code></pre>
    <Title mdxType="Title">Vertical overflow</Title>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "path=/directory/file.mdx src=https://gatsby-theme-carbon.now.sh",
        "path": "/directory/file.mdx",
        "src": "https://gatsby-theme-carbon.now.sh"
      }}>{`## Path and src w/ overflow

This example demonstrates the show more button. This example demonstrates the
show more button. This example demonstrates the show more button. This example
demonstrates the show more button. This example demonstrates the show more
button. This example demonstrates the show more button. This example
demonstrates the show more button. This example demonstrates the show more
button. This example demonstrates the show more button. This example
demonstrates the show more button. This example demonstrates the show more
button. This example demonstrates the show more button.
`}</code></pre>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{}}>{`\`\`\`markdown path=/directory/file.mdx src=https://gatsby-theme-carbon.now.sh
### Path and src

This code snippet provides both a \`path\` and a \`src\`.
\`\`\`
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/vendor/prism/includeLangs.js"
            }}>{`Available languages.`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`src`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The full url of a relevant link (source)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string indicating the filename or path. Due to markdown limitations this can only be a single word`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      